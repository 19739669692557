.containerWrapper{
    padding: 20px 0px;
}
.bookContainer{
    width: 100%;
}
.bookContainer .cardWrapper{
    display: flex!important;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 5px 20px;
    border-radius: 10px;
    margin: 20px 0px;
    gap: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: rgba(236, 236, 236, 0.507);
}
.bookContainer .cardWrapper .image{
    width: 100%!important;
    height: 180px;
    flex:2;
    background: rgba(170, 169, 169, 0.507);
    border-radius: 8px;
}
@media screen and (max-width: 500px) {
    .bookContainer .cardWrapper{
        flex-direction: column;
    }
    .bookContainer .cardWrapper .image{
        width: 100%!important;
        height: auto;
    }
}
@media screen and (max-width: 800px) {
    .bookContainer .cardWrapper .image{
        flex: 4
    }
}
.bookContainer .cardWrapper .image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}
.wrapper{
    flex: 7;
}
.bookContainer .name{
    font-size: 17px;
    font-family: var(--textFamily);
    color: var(--mainBlack);
    font-weight: 600;
}
.bookContainer .author{
    position: relative;
    top:-2px;
    color: rgb(212, 210, 210);
    font-size: 14px;
    font-family: var(--textFamily);
    text-transform: capitalize;
}
.bookContainer .testimony{
    font-size: 14px;
    font-family: var(--textFamily);
    color: var(--mainBlack);
    font-weight: 600;
    padding: 20px 0px;
}
.bookContainer .author span{
    color: rgb(177, 176, 176);
    font-size: 15px;
    padding-right: 20px;
}
.bookContainer .postcontent{
    width: 100%;
    padding: 10px 0px;
    text-align: left;
    font-family: var(--textFamily);
    color: var(--mainBlack);
    font-size: 14px;
}
.bookContainer .action{
    display: flex;
    gap: 2rem;
}

.bookContainer .action .del-btn{
    width: 100px;
    border-radius: 5px;
    background: crimson;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    color: #fff
}

.formpopupBody{
    position: absolute;
    width: auto;
    height: fit-content;
    margin: auto;
    background:#fff;
    padding:20px 20px;
    border-radius: 8px;
    margin-bottom: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
.popupContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(36, 37, 39, 0.788);
    /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.12); */
    z-index: 999;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0px;
   
}
.popupContainer .name{
    font-size: 15px; font-weight: 500px;
    font-family: var(--textFamily);
    padding-bottom: 20px;
    color: var(--mainBlack)
}
.popupContainer .action{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.popupContainer .action  button{
    width: 100px;
    border-radius: 5px;
    background: rgb(207, 207, 207);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    color: #fff;
    border: none
}
.popupContainer .action .yes{
    background: crimson;
}
.tabContent {
    width: 100%;
    display: none;
  }
  
  .active-tabContent {
    display: block;
    margin-top: 20px;
  }