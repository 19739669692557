td, th {
    border: 1px solid #ddd;
    padding: 8px;
}
  th{
    color: #333;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 0px
  }
  tr:nth-child(even) {
    background-color: rgb(79, 172, 201);
  }
  table{
    width: 100%
  }
  .post-content{
    margin: 0 20px;
  }
  .post-topic{
    max-width: 400px;
    line-height: 30px;
    text-align: justify;
  }
  td{
    color: #333;
  }
  .name{
    text-transform: capitalize;
  }
  .counter{
    text-align: center
  }
  .edit{
    padding: 5px 25px;
    text-transform: uppercase;
    background: rgba(184, 181, 181, 0.658);
    border: none;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    border-radius: 4px;
    transition: o.4s all ease;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .edit:hover{
    background:  rgba(184, 181, 181, 0.199);
    text-decoration: none;
  }
  .delete{
    padding: 5px 20px;
    text-transform: uppercase;
    background: rgba(230, 95, 95, 0.658);
    border: none;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    transition: o.4s all ease;
    border-radius: 4px;
  }
  .delete:hover{
    background: rgba(230, 95, 95, 0.281);
  }