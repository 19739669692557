.content-container {
    width: 100%;
    margin: 30px 0px;
}

.content-display {
    width: 70%;
    margin: 0 auto;
    font-family: var(--textFamily);
    color: var(--mainBlack)
}

.content-display h2 {
    font-family: var(--textFamily);
    color: var(--mainDark);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content-display h2 span{
    border: 1px solid var(--mainBlack);
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 15px;
    height: 35px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.4s all ease;
}
.content-display h2 span:hover{
    background: var(--purpleColor);
    color:#fff
}
.form-input {
    border: 1px solid var(--primaryColor);
    padding: 30px 10px;
    box-shadow: 10px 15px var(--primaryColor);
    border-radius: 5px;
}

.inputfield {
    width: 100%;
    height: 50px;
    outline: none;
    border: 1px solid var(--mainGrey);
    background: #fff;
    padding-left: 8px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.inputfield:focus,
.textareafield:focus {
    border: 1px solid var(--purpleColor);
}

.textareafield {
    width: 100%;
    outline: none;
    border: 1px solid var(--mainGrey);
    background: #fff;
    padding-left: 8px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.form-input label {
    font-size: var(--textFamily);
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    color: var(--mainDark)
}

.input-div {
    padding: 5px 0px;
}

.button-div {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.submitButton {
    padding: 10px 20px;
    background: var(--primaryColor);
    color: #fff;
    font-size: 16px;
    border: none;
    transition: 0.4s all ease;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    border-radius: 5px;
}
.disabled{
    pointer-events: none;
}
.message{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #fff;
    color: var(--mainBlack);
    font-size: 15px;
    color: green;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    font-weight: 600;
}
.submitButton:hover {
    background: var(--purpleColor);
}

.clear {
    background: rgba(207, 9, 9, 0.795);
}

.clear:hover {
    background: rgb(247, 8, 8);
}

.post-content {
    padding: 30px 0px;
    text-align: center;
}



.post-content .card img {
    width: 100%;
    height: 250px;
    object-fit: contain;
}

.post-content .card .image span {
    width: 100%;
    height: 250px;
    overflow: hidden;
    padding: 10px;
}

.post-content .name {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    padding: 10px 0px;
    border-bottom: thick double var(--primaryColor);
    margin-bottom: 20px
}

.post-content .location {
    padding-bottom: 20px;
}

.post-content .action {
    margin-bottom: 30px;
    padding: 2px 5px;
    display: flex;
    justify-content: space-between;
}

.post-content .edit {
    background: var(--primaryColor);
   
}

.post-content .edit:hover {
    background: var(--purpleColor);
}

.post-content .action .del-btn {
    padding: 2px 20px;
    background-color: crimson;
    border-radius: 5px;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .content-display {
        width: 95%;
        margin: 0 auto;
        font-family: var(--textFamily);
        color: var(--mainBlack)
    }
}